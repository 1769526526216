import React from "react";
import Connecteur from '../../components/Connecteur';
import MssanteLogo from '../../media/connecteurs/mssante-logo.png';

export default class TabPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: "prosanteconnect",
      title: "Pro Santé Connect",
      description: "Une aide à la mise en place de Pro Santé Connect.",
      content:
      <div>
        <div class="columns section-panel">
            <div class="column alt-column is-8">
                <div class="content is-medium">
                    <h2>Pro Santé Connect</h2>
                    <p>Pro Santé Connect permet aux professionnels de s’authentifier soit avec une e-CPS (application mobile) soit avec une carte CPS physique à tous les services numériques de santé raccordés à Pro Santé Connect.</p>
                    <p>À compter du 1er janvier 2023, l’implémentation de PSC sera obligatoire pour les services numériques en santé nationaux, territoriaux, ainsi que pour les services locaux qui y sont fortement intégrés.</p>
                    <p>Les identités fournies proviennent de l’Annuaire Santé, lui-même alimenté par les répertoires RPPS et FINESS. Pro Santé Connect permet de s’authentifier facilement à tous les services raccordés de la e-santé, avec les moyens d’authentification qui conviennent le mieux aux professionnels de santé et ce de manière sécurisée.</p>
                    <p>Cerise sur le gâteau : mettre en place Pro Santé Connect est rapide à faire et permet à tous les professionnels de santé de s'identifier en un clique à votre application.</p>
                </div>
            </div>
        </div>
      </div>

    };
  }

  render() {
    return (
        <Connecteur 
            content={this.state.content} 
            activePage={this.state.page} 
            title={this.state.title}
            description={this.state.description}
        />
    );
  }
}
